import styled from "styled-components";

export const Form = styled.form`
  padding-bottom: 40px;

  * {
    display: block;
  }

  label {
    font-size: 14px;
    line-height: 20px;
    color: rgba(12, 12, 13, 0.72);
    margin-bottom: 7px;
    font-weight: 700;
    letter-spacing: 0.1px;
  }

  div {
    margin-bottom: 29px;
  }

  select {
    appearance:none;
    width: 400px;
    height: 56px;
    border: 1px solid rgba(177, 177, 177, 1);
    border-radius: 5px;
    padding: 16px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    box-sizing: border-box;
    color: rgba(12, 12, 13, 0.72);
    background-color: #FFFFFF;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 366px;
    background-position-y: 16px;
  }
  
  input {
    width: 400px;
    height: 56px;
    border: 1px solid rgba(177, 177, 177, 1);
    border-radius: 5px;
    padding: 16px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    box-sizing: border-box;
    color: rgba(12, 12, 13, 0.72);
  }

  input[type=number] {
    width: 122px;
  }

  p.error {
    color: rgba(169, 31, 29, 1);
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    margin-top: 7px 0 0 0;
    letter-spacing: 0.5px;
  }

  div.checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;

    label {
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      color: rgba(12, 12, 13, 0.88);
      font-weight: 400;
      margin-left: 12px;
      letter-spacing: 0.25px;
    }
  }

  input[type=checkbox] {
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0px;
    border: 2px solid #29292a;
    border-radius: 5px;
    margin: 0;
  }

  input[type=checkbox]:checked::before {
    position: relative;
    top: -10px;
    left: 0px;
    content: '✔';
    font-size: 36px;
    color: #29292a;
    width: 20px;
    height: 20px;
  }

  input[type=submit] {
    display: block;
    width: auto;
    height: auto;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #000000;
    padding: 10px 16px;
    color: #FFFFFF;
    background-color: rgba(50, 95, 100, 1);
    border-radius: 4px;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(50, 95, 100, 0.8);
    }
  }

`