import { atomWithQuery } from 'jotai-urql'
import { getApiClient } from '../services/graphql-api'

export type Trial = {
  id: string,
  name: string,
  _count: {
    participants: number
  }
}

type TrialsList = {
  trials: Trial[]
}

export const trialListAtom = atomWithQuery<TrialsList>({
  getClient: getApiClient,
  query: `
{
  trials {
    id,
    name,
    _count {
      participants
    }
  }
}
  `
})