import styled from "styled-components";
import { ReactComponent as ChevronRight } from '../assets/svg/chevron-right.svg';

type ListElement = {
  id: string;
  title: string;
  description: string;
}

type ListProps = {
  elements: ListElement[];
}

const Wrapper = styled.ul`
  padding: 0;
  list-style-type: none;
`

const Element = styled.li`
  position: relative;
  height: 60px;
  border-bottom: 1px solid rgba(12, 12, 13, 0.08);
  margin-bottom: 20px;
`

const ElementTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(12, 12, 13, 0.88);
  margin: 0;
`

const ElementDescription = styled.p`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(12, 12, 13, 0.48);
  margin: 0;
`

const ElementAction = styled(ChevronRight)`
  position: absolute;
  width: 5px;
  height: 14px;
  right: 20px;
  bottom: 34px;
`

export const List = (props: ListProps) => {

  return (
    <Wrapper>
      {props.elements.map((element, index) => (
        <Element key={index}>
          <ElementTitle>{element.title}</ElementTitle>
          <ElementDescription>{element.description}</ElementDescription>
          <ElementAction />
        </Element>
      ))}
    </Wrapper>
  )
}