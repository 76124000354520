import {
  createClient,
  cacheExchange,
  fetchExchange
} from 'urql'

console.log(process.env)

const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:4000/graphql' : '/graphql'

export const apiClient = createClient({
  url: API_URL,
  exchanges: [cacheExchange, fetchExchange],
  fetchOptions: () => {
    return { headers: {
      authorization: `Bearer BOOM`
    } }
  },
})

export const getApiClient = () => {
  return apiClient
}
