import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CurebaseLogo } from '../assets/svg/curebase-logo.svg';
import { NavLink } from 'react-router-dom';

type LayoutProps = {
  children: React.ReactNode;
}

const Body = styled.div`
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
`

const Header = styled.header`
  width: 100%;
  box-sizing: border-box;
  background-color: #F5F5F5;
  color: #0C0C0D7A;
  height: 148px;
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled(CurebaseLogo)`
  width: 286px;
  height: 80px;
`

const Menu = styled.nav`
  display: flex;
  gap: 40px;
`

const MenuLink = styled(NavLink)`
  text-decoration: none;
  color: rgba(12, 12, 13, 0.48);
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.01px;

  &:visited {
    color: rgba(12, 12, 13, 0.48);
  }

  &:hover {
    color: rgba(50, 95, 100, 0.5);
  }

  &.active {
    color: rgba(50, 95, 100, 1);
  }
`

const Page = styled.main`
  flex: 1;
  width: 100%;
  padding: 0 60px;
  box-sizing: border-box;
  overflow-y: scroll;
  justify-content: center;
  display: flex;

  &::-webkit-scrollbar {
      width: 3px;
  }
  
  &::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: #F5F5F5;
  }
  
  &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgba(50, 95, 100, 1);
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 860px;
`

export const Layout = (props: LayoutProps) => {
  return (
    <Body>
      <Header>
        <Logo title="Curebase" />
        <Menu>
          <MenuLink to="/">Participants</MenuLink>
          <MenuLink to="/trials">Trials</MenuLink>
        </Menu>
      </Header>
      <Page>
        <Content>
          <React.Suspense fallback={<div>Loading...</div>}>
            {props.children}
          </React.Suspense>
        </Content>
      </Page>
    </Body>
  )
}