import { useAtom } from 'jotai'
import { PageTitle } from "../components/page-title"
import { Trial, trialListAtom } from "../state/trials"
import { List } from '../components/list'

const formatTrial = (trial: Trial) => {
  return {
    id: trial.id,
    title: trial.name,
    description: `${trial._count.participants} participants`
  }
}

export const TrialsPage = () => {
  const [trialsRequest] = useAtom(trialListAtom)
  const trials = trialsRequest.data?.trials ? trialsRequest.data?.trials.map(formatTrial) : [];
  return (
    <>
      <PageTitle title="Trials" />
      <List elements={trials} />
    </>
  )
}