import styled from "styled-components";

type PageTitleProps = {
  title: string;
  actionTitle?: string;
  onActionClick?: () => void;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
`

const Title = styled.h1`
  display: block;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  margin: 0;
`

const Action = styled.button`
  display: block;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #000000;
  padding: 10px 16px;
  color: #FFFFFF;
  background-color: rgba(50, 95, 100, 1);
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(50, 95, 100, 0.8);
  }
`

export const PageTitle = (props: PageTitleProps) => {
  const { title, actionTitle, onActionClick } = props;

  return (
    <Wrapper>
      <Title>{title}</Title>
      {actionTitle && (
        <Action onClick={onActionClick}>{actionTitle}</Action>
      )}
    </Wrapper>
  )
}