import { atomWithQuery, atomWithMutation } from 'jotai-urql'
import { getApiClient } from '../services/graphql-api'
import { atom } from 'jotai'

export type Participant = {
  id: string,
  name: string,
  createdAt: string,
}

type ParticipantsList = {
  participants: Participant[]
}

export type EnrollParticipant = {
  enrollParticipant: {
    id: string,
    isValid: boolean,
  }
}

export type EnrollParticipantInput = {
  name: string
  trialId: string;
  height: number;
  weight: number;
  hasDiabetes: boolean;
  hadCovid: boolean;
}

export type EnrollParticipantInputs = {
  participant: EnrollParticipantInput
}

export const participantsListAtom = atomWithQuery<ParticipantsList>({
  getClient: getApiClient,
  query: `
{
  participants {
    id,
    name,
    createdAt
  }
}
  `
})

export const enrollParticipantAtom = atomWithMutation<EnrollParticipant, EnrollParticipantInputs>(`
mutation EnrollParticipant($participant: EnrollParticipant!) {
  enrollParticipant(participant: $participant) {
    id,
    isValid,
  }
}  
`, getApiClient)

export const hasNewParticipantsAtom = atom<boolean>(false)