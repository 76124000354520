import React from 'react';
import { Layout } from './components/layout';
import { Normalize } from 'styled-normalize'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ParticipantsPage } from './pages/participants';
import { TrialsPage } from './pages/trials';
import { EnrollParticipantPage } from './pages/enroll-participant';
import { EnrollParicipantSubmitPage } from './pages/enroll-participant-submit';

function App() {
  return (
    <>
      <Normalize />
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<ParticipantsPage />} />
            <Route path="/trials" element={<TrialsPage />} />
            <Route path="/enroll-participant" element={<EnrollParticipantPage />} />
            <Route path="/enroll-participant/success" element={<EnrollParicipantSubmitPage isValid={true} />} />
            <Route path="/enroll-participant/fail" element={<EnrollParicipantSubmitPage isValid={false} />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    
    </>
  );
}

export default App;
