import { Suspense } from "react";
import { PageTitle } from "../components/page-title"
import { useForm, SubmitHandler } from "react-hook-form"
import { trialListAtom } from "../state/trials";
import { useAtom } from "jotai";
import { Form } from "../components/form";
import { EnrollParticipantInput, enrollParticipantAtom, hasNewParticipantsAtom } from "../state/participants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const EnrollParticipantPageContent = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm<EnrollParticipantInput>()
  const [trialsRequest] = useAtom(trialListAtom)
  const [, enrollMutation ] = useAtom(enrollParticipantAtom)
  const [, setHasNewParticipants] = useAtom(hasNewParticipantsAtom)
  const trials = trialsRequest.data?.trials ? trialsRequest.data?.trials : [];

  const onSubmit: SubmitHandler<EnrollParticipantInput> = async (data) => {
    setSubmitting(true)
    const result = await enrollMutation({ participant: data })
    if (result.data?.enrollParticipant.isValid) {
      setHasNewParticipants(true)
      navigate('/enroll-participant/success')
    } else {
      navigate('/enroll-participant/fail')
    }
    setSubmitting(false)
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>Name</label>
          <input {...register('name', {required: true, minLength: 2})} />
          {errors.name && <p className="error">This is a required field</p>}
        </div>
        <div>
          <label>Height (inches)</label>
          <input type="number" {...register("height", { required: true, min: 1, max: 200, valueAsNumber: true })} />
          {errors.height && <p className="error">This is a required field</p>}
        </div>
        <div>
          <label>Weight (pounds)</label>
          <input type="number" {...register("weight", { required: true, min: 1, max: 1000, valueAsNumber: true })} />
          {errors.weight && <p className="error">This is a required field</p>}
        </div>
        <div className="checkbox">
          <input type="checkbox" {...register("hasDiabetes")} />
          <label>I have diabetes</label>
        </div>
        <div className="checkbox">
          <input type="checkbox" {...register("hadCovid")} />
          <label>I had COVID-19</label>
        </div>
        <div>
          <label>Trial</label>
          <select {...register("trialId", { required: true })}>
            {trials.map(trial => (<option key={trial.id} value={trial.id}>{trial.name}</option>))}
          </select>
          {errors.trialId && <p className="error">This is a required field</p>}
        </div>
        <input type="submit" value={submitting ? 'Saving' : 'Save'} disabled={submitting} />
      </Form>
    </>
  )
}

export const EnrollParticipantPage = () => {
  return (
    <>
      <PageTitle title="Enroll a participant" />
      <Suspense>
        <EnrollParticipantPageContent />
      </Suspense>
    </>
  )
}