import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as ErrorSVG } from '../assets/svg/error.svg';
import { ReactComponent as OkSVG } from '../assets/svg/ok.svg';

type EnrollParicipantSubmitPageProps = {
  isValid: boolean;
}

const Wrapper = styled.div`
  padding-top: 202px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h1`
  font-size: 32px;
  line-height: 40px;
  margin: 0 0 7px 0;
`

const SubTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 1);
  margin: 0 0 20px 0;
`

const Button = styled(NavLink)`
  display: block;
  width: auto;
  height: auto;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 10px 16px;
  color: #FFFFFF;
  background-color: rgba(50, 95, 100, 1);
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.1px;

  &:hover {
    background-color: rgba(50, 95, 100, 0.8);
  }
`

const ErrorIcon = styled(ErrorSVG)`
  width: 96px;
  height: 96px;
  margin: 0 0 30px 0;
`

const OkIcon = styled(OkSVG)`
  width: 96px;
  height: 96px;
  margin: 0 0 30px 0;
`

export const EnrollParicipantSubmitPage = (props: EnrollParicipantSubmitPageProps) => {
  const { isValid } = props;

  const icon = isValid ? <OkIcon /> : <ErrorIcon />;
  const title = isValid ? 'Participant is eligible' : 'Participant is not eligible';
  const subTitle = isValid ? 'The participant can participate to this study' : 'The participant can\'t participate to this study';

  return (
    <Wrapper>
      {icon}
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <Button to="/">OK</Button>
    </Wrapper>
  )
}