import { useNavigate } from "react-router-dom";
import { PageTitle } from "../components/page-title"
import { hasNewParticipantsAtom, Participant, participantsListAtom } from "../state/participants"
import { useAtom } from "jotai"
import { List } from '../components/list'
import { useEffect } from "react";

const formatParticipant = (participant: Participant) => {
  const date = new Date(participant.createdAt)
  const formatedDateMonth = date.toLocaleDateString('en-US', { month: 'short' })
  const formatedDateDay = date.toLocaleDateString('en-US', { day: 'numeric' })

  return {
    id: participant.id,
    title: participant.name,
    description: `Enrolled in ${formatedDateMonth}, ${formatedDateDay}`
  }
}

const sortParticipantsByCreateAt = (a: Participant, b: Participant) => {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
}

export const ParticipantsPage = () => {
  const navigate = useNavigate();
  const [participantsRequest, participantsReexecute] = useAtom(participantsListAtom)
  const [hasNewParticipants, setHasNewParticipants] = useAtom(hasNewParticipantsAtom)
  const participants = participantsRequest.data?.participants ? participantsRequest.data?.participants.sort(sortParticipantsByCreateAt).map(formatParticipant) : [];

  const onEnrollClick = () => {
    navigate('/enroll-participant')
  }

  useEffect(() => {
    if (hasNewParticipants) {
      setHasNewParticipants(false)
      participantsReexecute({ requestPolicy: 'network-only' })
    }
  }, [hasNewParticipants, participantsReexecute, setHasNewParticipants])

  return (
    <>
      <PageTitle title="Participants" actionTitle="Enroll a participant" onActionClick={onEnrollClick} />
      <List elements={participants} />
    </>
  )
}